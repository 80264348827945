import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Thankyou from "./Components/Thankyou";
import Screen from "./Components/Screen";
import RegisterContainer from "Components/Contact/RegisterContainer";
import Donate from "Components/Contact/Donate";
import ThankyouRegister from "Components/ThankyouRegister";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Screen />} />
          <Route exact path="/register" element={<RegisterContainer />} />
          <Route exact path="/donate" element={<Donate />} />
          <Route exact path="thankyou/:name" element={<Thankyou />} />
          <Route exact path="tu/:name" element={<ThankyouRegister />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
