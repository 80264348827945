import { Form, Card, Row, Button, Image } from "react-bootstrap";
import { useRef } from "react";
import ContactStyle from "./Contact.module.css";
import VenmoImg from "../../images/venmo.jpg";
import { Link } from "react-router-dom";

const Donate = () => {
  const contactTitle = ContactStyle.contactTitle;
  const formStyle = ContactStyle.formStyle;
  const btn = ContactStyle.btn;
  const btnWrapper = ContactStyle.btnWrapper;

  const refForm = useRef();

  return (
    <>
      <Card
        style={{ border: "none", marginTop: "1rem", backgroundColor: "white" }}
      >
        <Card.Body className={formStyle}>
          <Form id="contact" ref={refForm} autoComplete="on">
            <Row>
              <Card.Title className={contactTitle}>MAKE A DONATION</Card.Title>
            </Row>
            <hr />

            <div className={btnWrapper}>
              <Button
                href="https://venmo.com/DrTeresaBryant"
                variant="light"
                type="submit"
                value="send"
                className={btn}
              >
                <span>Scan or Tap QR Code to Give Via Venmo</span>
                <Image src={VenmoImg} className="img-fluid" alt="Venmo" />
              </Button>
              <hr />
              <Link style={{ color: "grey" }} to="/register">
                GO BACK TO REGISTER
              </Link>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default Donate;
